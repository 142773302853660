import React from 'react'
import Reflux from 'reflux'
import Store from '../Store.jsx'
import Actions from '../Actions.jsx'

export default React.createClass({
  mixins: [Reflux.connect(Store)],

  translate: function (key, replace) {
    if (process.env.NODE_ENV !== 'production' && ! this.props.translate[key]) {
      console.warn(`Translate String "${key}" Not Found in Props`);
    }
    var string = this.props.translate[key] ? this.props.translate[key] : key;
    return string.replace('%s', replace);
  },

  selectStore: function (selected) {
    Actions.setSelectedStore(selected ? this.state.visibleStore.store_id : null)
  },

  getOpeningHours: function (){
    let store = this.state.visibleStore;
    let d = new Date();
    let weekday = new Array(7);
    weekday[0]=  "sunday";
    weekday[1] = "monday";
    weekday[2] = "tuesday";
    weekday[3] = "wednesday";
    weekday[4] = "thursday";
    weekday[5] = "friday";
    weekday[6] = "saturday";

    let n = weekday[d.getDay()];
    let openToday = store[n+'_status'] == 1;
    let openingHours = store.openinghours[n];

    return (
      openToday ? 'Vandaag geopend · '+ openingHours  : 'Vandaag gesloten'
    )
  },

  getPickAsStore: function(pickupEnabled, isSelected){
    if(pickupEnabled) {
      return (
        <a className={isSelected ? 'selected' : ''}
           onClick={this.selectStore.bind(this, !isSelected)}>
          <i className="icon icon-basic-geolocalize-01"></i><br />
          {isSelected ? Translator.translate('Selected') : Translator.translate('Pick as store')}
        </a>
      )
    } else {
      return (
        <div className="disabled pick-as-store-btn" data-toggle="tooltip" title={Translator.translate("This store cannot be selected as pickup location")}>
          <i className="icon icon-basic-geolocalize-01"></i><br />
          {Translator.translate('Pick as store')}
        </div>
      );
    }
  },

  preventBubbling: function(e){
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    return null;
  },

  render: function () {
    if (! this.state.visibleStore) {
      return null
    }

    var isSelected = this.state.selectedStore &&
         this.state.selectedStore.store_id == this.state.visibleStore.store_id

    return (
      <div className="store-popover-wrapper">
        <div className="store-popover" onClick={this.preventBubbling}>
          <div className="store-popover-heading">
            <div className="pull-right close-btn" onClick={Actions.setVisibleStore.bind(this,null)}>
              <i className="icon-remove icon-3x"/>
            </div>
            <div className="store-name">
              {this.state.visibleStore.store_name}
            </div>
            <div className="store-address">
              {this.state.visibleStore.address+', '+this.state.visibleStore.city}
            </div>
          </div>
          <div className="store-popover-body">
            <div className="row">
              <div className="col-xs-4 text-center">
                <a href={'tel:'+this.state.visibleStore.store_phone}>
                  <i className="icon icon-basic-smartphone"></i><br />
                  {this.state.visibleStore.store_phone}
                </a>
              </div>
              <div className="col-xs-4 text-center">
                <a href={this.state.visibleStore.store_view_url + this.state.visibleStore.url_id_path}>
                  <i className="icon icon-basic-info"></i><br />
                  {Translator.translate('Store page')}
                </a>
              </div>
              <div className="col-xs-4 text-center">
                {this.getPickAsStore(parseInt(this.state.visibleStore.pickup_enabled), isSelected)}
              </div>
            </div>
          </div>
          <div className="store-popover-footer">
            {'Openingtijden: ' + this.getOpeningHours()}
          </div>
        </div>
      </div>
    )
  }
})

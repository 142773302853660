import React from 'react'
import Reflux from 'reflux'
import Store from '../Store.jsx'
import Actions from '../Actions.jsx'

export default React.createClass({
  mixins: [Reflux.connect(Store)],

  translate: function (key, replace) {
    if (process.env.NODE_ENV !== 'production' && ! this.props.translate[key]) {
      console.warn(`Translate String "${key}" Not Found in Props`);
    }
    var string = this.props.translate[key] ? this.props.translate[key] : key
    return string.replace('%s', replace)
  },

  selectStore: function(e) {
    Actions.setSelectedStore(e.target.value)
  },

  toggleLocator: function () {
    Actions.setVisibilityGoogleMap(! this.state.visibleGoogleMap);
  },

  render: function () {
    if (!this.state.geoStores || !this.state.geoStores.length) {
      return (
        <div className="storepickup-checkout loading"></div>
      )
    }

    return (
      <div className="storepickup-checkout">
        <div>
          <div>
            {this.translate('service_location_text')}
            <br />
            <div className="store-select">
              {this.renderSelectedStore()}
              <div className="btn btn-primary" onClick={this.toggleLocator}>{this.state.selectedStore ? Translator.translate('Change your store') : Translator.translate('Pick your store')}</div>
            </div>
          </div>
          <select onChange={this.selectStore} id="storepickup_store_id" className="required-entry" name="storepickup_store_id"
                  value={this.state.selectedStore ? this.state.selectedStore.store_id : ''}>
            <option value="">{this.translate('Select Store...')}</option>
            {this.renderStores()}
          </select>
        </div>
      </div>
    )
  },

  componentDidUpdate: function(){
    shippingMethod.bindFields();
  },

  renderStores: function () {
    let noPickupLocationStr = this.translate('No Pickup Location');
    let { renderCity } = this.props;

    return this.state.geoStores.map(function (store) {
      let isRestricted = parseInt(store.is_restricted);
      let pickupDisabled = !parseInt(store.pickup_enabled);

      return (
        <option disabled={isRestricted || pickupDisabled} value={store.store_id} key={store.store_id}>
          {store.store_name} {renderCity ? ', '+store.city : null}
          {pickupDisabled ? ' ('+noPickupLocationStr+')' : null}
          {isRestricted ? ' ('+store.is_restricted_reason+')' : null}
        </option>
      )
    })
  },

  renderSelectedStore: function() {
    if (! this.state.selectedStore) {
      return null;
    }

    return (
      <div className="storepickup-checkout-selected text-success">
        <strong>{this.state.selectedStore ? this.state.selectedStore.store_name : null}</strong><br />
        <span>{this.state.selectedStore ? this.state.selectedStore.address_formatted : null}</span>
      </div>
    )
  }
})

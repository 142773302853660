import React from 'react'
import Reflux from 'reflux'
import Store from '../Store.jsx'
import Actions from '../Actions.jsx'
import GoogleMap from './GoogleMap.jsx'
import List from './List.jsx'

export default React.createClass({
  mixins: [Reflux.connect(Store)],

  translate: function (key, replace) {
    if (process.env.NODE_ENV !== 'production' && ! this.props.translate[key]) {
      console.warn(`Translate String "${key}" Not Found in Props`);
    }
    var string = this.props.translate[key] ? this.props.translate[key] : key
    return string.replace('%s', replace)
  },

  toggleLocator: function () {
    Actions.setVisibilityGoogleMap(! this.state.visibleGoogleMap);
  },

  render: function () {
    // if (! this.state.visibleGoogleMap) {
    //   return null
    // }

    return (
      <div className="storepickup-google-maps-inline">
        <div className="storepickup-overlay-wrapper">
          <div className="storepickup-overlay">
            <div className="google-maps">
              <GoogleMap preselectedMapPosition={this.props.preselectedMapPosition} />
            </div>
            <List />
          </div>
        </div>
      </div>
    )
  }
})


import React from 'react'
import Reflux from 'reflux'
import Store from '../Store.jsx'
import Actions from '../Actions.jsx'
import ListItemInfo from './ListItemInfo.jsx'

export default React.createClass({
  mixins: [Reflux.connect(Store)],
  
  findKeywordLocation: function(keyword) {
    Actions.findKeywordLocation(keyword);
  },

  removeCustomLocation: function(keyword) {
    Actions.removeCustomLocation();
  },

  render: function () {
    return (
      <div className={(this.state.isListActive ? 'active ' : '') + 'storepickup-list list-group'}>
        {this.renderStoreFilter()}
        {this.renderStores()}
      </div>
    )
  },
  renderStores: function () {
    let self = this

    let visibleStoreCounter = 0;
    let stores =  this.state.geoStores.map(function (store) {
      if (store.hidden || visibleStoreCounter >= 50 || store.store_name == 'default') {
        return null;
      }
      visibleStoreCounter++
      return (
        <ListItem store={store} {...self.state} key={store.store_id}/>
      )
    })

    if(visibleStoreCounter == 0 && this.state.keyword){
      stores =
        <div className={'list-group-item storepickup-list-item disabled-list-item'}>
          <h4 className="list-group-item-heading">
              {"Geen winkels in '" + this.state.keyword.charAt(0).toUpperCase()+this.state.keyword.slice(1) + "'."}
          </h4>
        </div>
    }
    
    let searchBtn = this.state.keyword
      && this.state.keyword.length > 2 ?
        <a className={(this.state.showKeywordStores ? 'search-btn-as-list-item ' : '') + 'btn btn-primary btn-search'} key={'keywordBtn'} onClick={this.findKeywordLocation.bind(this, this.state.keyword)}>
          {this.state.showKeywordStores ? 'Winkels in de buurt van ' + this.state.keyword.charAt(0).toUpperCase()+this.state.keyword.slice(1) : "Zoek in de buurt van '"+this.state.keyword.charAt(0).toUpperCase()+this.state.keyword.slice(1)+"'"}
        </a> : ''

    let keywordStores;
    if(this.state.keywordStores && this.state.showKeywordStores) {
      keywordStores = this.state.keywordStores.map(function (store) {
        if (visibleStoreCounter >= 12 || store.store_name == 'default') {
          return null;
        }
        visibleStoreCounter++
        return (
          <ListItem store={store} {...self.state} key={store.store_id}/>
        )
      })
    }
    
    return [stores, searchBtn, keywordStores]

  },
  renderStoreFilter: function () {
    var self = this
    return (
      <ListFilter key="listFilter" />
    )
  }
})

var ListItem = React.createClass({
  selectStore: function (selected) {
    Actions.setSelectedStore(selected ? this.props.store.store_id : null)
  },
  setVisibleStore: function(selected) {
    Actions.setVisibleStore(selected ? this.props.store.store_id:  null)
  },
  preventBubbling: function(e){
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    return null;
  },

  getPickAsStore: function(pickupEnabled, isSelected){
    if(pickupEnabled) {
      return (
        <a className={isSelected ? 'selected disabled btn btn-primary ' : 'btn btn-primary'}
           onClick={this.selectStore.bind(this, !isSelected)}>
          {isSelected ? Translator.translate('Selected') : Translator.translate('Pick as store')}
        </a>
      )
    } else {
      return (
        <div className="btn btn-primary pick-as-store-btn" onClick={this.preventBubbling} data-toggle="tooltip" title={Translator.translate("This store cannot be selected as pickup location")}>
          {Translator.translate('Pick as store')}
        </div>
      )
    }
  },

  render: function () {
    var isVisible = this.props.visibleStore &&
      this.props.visibleStore.store_id == this.props.store.store_id
    var isSelected = this.props.selectedStore && this.props.visibleStore &&
       this.props.selectedStore.store_id == this.props.visibleStore.store_id

    return (
      <div className={isVisible ? 'list-group-item storepickup-list-item tag_'+this.props.store.tag_ids+' active' : 'list-group-item storepickup-list-item tag_'+this.props.store.tag_ids}
           data-pickup={parseInt(this.props.store.pickup_enabled) ? '1' : '0'}
           onClick={this.setVisibleStore.bind(this, !isVisible ? 1 : null)}>
        <h4 className="list-group-item-heading">
            {this.props.store.store_name}
        </h4>
        <div className="list-group-item-text media">
          {this.props.store.store_view_url && this.props.store.url_id_path &&
            <a className="pull-right store-page" href={this.props.store.store_view_url+this.props.store.url_id_path}>
            Meer informatie <i className="icon-external-link"/>
            </a>
          }

          <address className="media-body">
            {this.props.store.address+', '+this.props.store.city}
          </address>
        </div>
        {isVisible ? (
            <div className="hidden-xs">
              {this.getPickAsStore(parseInt(this.props.visibleStore.pickup_enabled), isSelected)}
            </div>
        ) : null}
        {isVisible ? (
          <div className="hidden-sm hidden-md hidden-lg">
            <div className="mobile-storepickup-backdrop" onClick={this.setVisibleStore.bind(this, null)}></div>
            <ListItemInfo />
          </div>
        ) : null}
      </div>
    )
  }
})

var ListFilter = React.createClass({
  mixins: [Reflux.connect(Store)],

  setKeywordFilter: function(event) {
    Actions.setKeywordFilter(event.target.value);
  },

  setListActiveState: function(){
    Actions.setListActiveState(true);
  },

  setBackBtnAction: function(event){
    if(this.state.isListActive) {
      Actions.setListActiveState(false);
      Actions.setVisibleStore(null);
    } else {
      Actions.setVisibilityGoogleMap(false);
    }
  },
  
  retryFindGeoLocation: function() {
    Actions.retryFindGeoLocation();
  },

  render: function () {
    return (
      <div className={'list-filter storepickup-list-filter'}>
        <i className="icon icon-arrows-left hidden-sm hidden-md hidden-lg" onClick={this.setBackBtnAction} />
        <input type="text" className="filter-input" placeholder="Zoek je winkel..."
               onBlur={this.setKeywordFilter} onKeyUp={this.setKeywordFilter} onFocus={this.setListActiveState} />
        <div className="get-current-location" onClick={this.retryFindGeoLocation.bind(this, null)}>
          <i className="icon icon-crosshairs" />
        </div>
      </div>
    )
  }
})

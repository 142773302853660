import React from 'react'
import Reflux from 'reflux'
import Store from '../Store.jsx'
import Actions from '../Actions.jsx'
import GoogleMap from './GoogleMap.jsx'
import List from './List.jsx'

export default React.createClass({
  mixins: [Reflux.connect(Store)],

  translate: function (key, replace) {
    if (process.env.NODE_ENV !== 'production' && ! this.props.translate[key]) {
      console.warn(`Translate String "${key}" Not Found in Props`);
    }
    var string = this.props.translate[key] ? this.props.translate[key] : key
    return string.replace('%s', replace)
  },

  toggleLocator: function () {
    Actions.setVisibilityGoogleMap(! this.state.visibleGoogleMap);
  },

  hideMsg: function () {
    Actions.setHideGoogleMapMsg(true);
  },

  renderMsg: function () {
    if(!this.props.msg || this.state.hideGoogleMapMsg) {
      return;
    }

    return (
      <div className="msg">
        <div className="msg-close" onClick={this.hideMsg}>
          <i className="icon-remove icon-3x"/>
        </div>
        <div className="msg-inner" dangerouslySetInnerHTML={{__html: this.props.msg}}></div>
      </div>
    )
  },

  render: function () {
    if (! this.state.visibleGoogleMap) {
      return null
    }

    return (
      <div className="storepickup-google-maps-popup">
        <div className="storepickup-overlay-wrapper">
          <div className="storepickup-overlay">
            <div className="storepickup-overlay-heading">
              {this.translate('Retail store finder')}
            </div>
            <div className="google-maps">
              {this.renderMsg()}
              <GoogleMap />
            </div>
            <List />
            <a href="javascript:void(0)" className="storepickup-overlay-close fullscreen-overlay-close" onClick={this.toggleLocator}>
              <i className="icon-remove icon-3x"/>
            </a>
          </div>
        </div>
      </div>
    )
  }
})

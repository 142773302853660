
import React from 'react'
import Reflux from 'reflux'
import Store from '../Store.jsx'
import Actions from '../Actions.jsx'
import {GoogleMapLoader, GoogleMap, Marker, InfoWindow} from 'react-google-maps'
import ListItemInfo from './ListItemInfo.jsx'

/**
 * Select a store googlemap
 */
export default React.createClass({
  mixins: [Reflux.connect(Store)],

  markers: function () {
    var markers = [{
      position: {
        lat: (!this.state.geoLocation.latitude) ? this.state.geoLocation.defaultLat : this.state.geoLocation.latitude,
        lng: (!this.state.geoLocation.longitude) ? this.state.geoLocation.defaultLong : this.state.geoLocation.longitude
      },
      key: 'current',
      draggable: true,
      label: '⌂',
      store: {}
    }]

    this.state.geoStores.forEach(function (store) {

      let markerData = {
        position: {
          lat: parseFloat(store.store_latitude),
          lng: parseFloat(store.store_longitude)
        },
        key: 'sl-marker-' + store.store_id,
        store: store
      }
      if(store.tag_data[0] && store.tag_data[0]['icon']) {
        markerData.icon = store.tag_icon_path + store.tag_data[0]['icon'];
      }

      markers.push(markerData);
    })

    return markers
  },

  renderMarkers: function () {
    var self = this

    return this.markers().map(function (marker) {
      return (
        <Marker {...marker} onClick={self.selectStore.bind(self, marker.store, marker.store.zipcode)}>
          {self.renderInfoWindow(marker)}
        </Marker>
      )
    })
  },

  renderInfoWindow: function(marker) {
    if (marker.store != this.state.visibleStore) {
      return null;
    }

    return (
        <InfoWindow>
          <ListItemInfo />
        </InfoWindow>
    )
  },

  getInitialState: function () {
    return {
      directions: null
    }
  },

  getDefaultProps: function() {
    return {
      containerProps: {
        style: {
          width: "100%",
          height: "100%"
        }
      }
    }
  },

  selectStore: function (store, storePostcode = false) {
    if(storePostcode){
      // this.state.keyword = storePostcode
      // Actions.setKeywordFilter(storePostcode)
      // jQuery('.filter-input').val(storePostcode)
    }
    Actions.setVisibleStore(store.store_id)
  },

  getGeoLocation: function() {
    return Actions.findGeoLocation();
  },

  retryGeoLocation: function() {
    return Actions.onRetryFindGeoLocation();
  },

  render: function () {
    if (!this.state.geoLocation || (!this.state.geoLocation.latitude && !this.state.geoLocation.longitude)) {
      var location = this.getGeoLocation();
      // return (<span>Accept Location Request Please</span>)
    }
    
    if(this.state.geoLocation && this.state.geoLocation.source == 'ip') {
      // this.retryGeoLocation();  
    }
    
    var lat = (!this.state.geoLocation.latitude) ? this.state.geoLocation.defaultLat : this.state.geoLocation.latitude
    var lng = (!this.state.geoLocation.longitude) ? this.state.geoLocation.defaultLong : this.state.geoLocation.longitude
    var zoom = 9;

    if(this.props.preselectedMapPosition) {
      lat = parseFloat(this.props.preselectedMapPosition.lat);
      lng = parseFloat(this.props.preselectedMapPosition.lng);
      zoom = 15;
    }

    return (
      <GoogleMap
        containerProps={this.props.containerProps}
        ref="storelocatormap"
        defaultZoom={zoom}
        maxZoom={11}
        defaultOptions={{mapTypeControl: false}}
        defaultCenter={{lat: lat, lng: lng}}>
        {this.renderMarkers()}
      </GoogleMap>
    )
  }
})
